import React from "react";
import { BrowserRouter, Route, Routes, } from "react-router-dom";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";

//project components
import theme from "./Theme";
import Header from "../src/Components/Header";
import Footer from "../src/Components/Footer";
import Divider from "@mui/material/Divider";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// pick a date util library
import DateFnsUtils from '@date-io/date-fns';
import FlightSearch from "./Pages/FlightSearch";
import FlightRevalidation from "./Pages/FlightRevalidation";
import FlightBooking from "./Pages/FlightBooking";
import PostTicketing from "./Pages/PostTicketing";
import Resources from "./Pages/Resources";
import Checklist from "./Pages/Checklist";
import Certification from "./Pages/Certification";
import FAQ from "./Pages/FAQ";
import SignIn from "./Pages/SignIn";
import Introduction from "./Pages/Introduction";
import Workflow from "./Pages/Workflow";
import ProtectedRoute from "./Components/ProtectedRoute";

function App() {
  return (
    <ThemeProvider theme={theme}>
  <div>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <BrowserRouter>
        <Header />
        <Divider />
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/SignIn" element={<SignIn />} />
          <Route
            path="/Resources"
            element={
              <ProtectedRoute redirectPath="/">
                <Resources />
              </ProtectedRoute>
            }
          />
          <Route
            path="/FAQ"
            element={
              <ProtectedRoute redirectPath="/">
                <FAQ />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Workflow"
            element={
              <ProtectedRoute redirectPath="/">
                <Workflow />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Certification"
            element={
              <ProtectedRoute redirectPath="/">
                <Certification />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Checklist"
            element={
              <ProtectedRoute redirectPath="/">
                <Checklist />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PostTicketing"
            element={
              <ProtectedRoute redirectPath="/">
                <PostTicketing />
              </ProtectedRoute>
            }
          />
          <Route
            path="/FlightBooking"
            element={
              <ProtectedRoute redirectPath="/">
                <FlightBooking />
              </ProtectedRoute>
            }
          />
          <Route
            path="/FlightRevalidation"
            element={
              <ProtectedRoute redirectPath="/">
                <FlightRevalidation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/FlightSearch"
            element={
              <ProtectedRoute redirectPath="/">
                <FlightSearch />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Introduction"
            element={
              <ProtectedRoute redirectPath="/">
                <Introduction />
              </ProtectedRoute>
            }
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </LocalizationProvider>
  </div>
</ThemeProvider>

  );
}

export default App;
