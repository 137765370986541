import React from "react";
import { Container, Typography } from "@mui/material";
import { RedocStandalone } from "redoc";
import headerback from "../assets/image/headerbackground.png";

function FlightBooking() {
  const PostTicketingDoc  =   "https://docs.getfares.com/apidocs/PostTicketing.json";
  return (
    <div>
            <div
        style={{
          backgroundImage: "url(" + headerback + ")",
          backgroundSize: "cover",
          height: "10vh",
        }}
      >
        <Container>
          <Typography
            variant="h4"
            style={{ paddingTop: "1.5%", marginBottom: "10px", color: "white" }}
          >
            Post Ticketing
          </Typography>
        </Container>
      </div>
      <RedocStandalone
        specUrl={PostTicketingDoc}
        options={{
          nativeScrollbars: true,
          expandDefaultServerVariables: true,
          expandResponses: true,
          jsonSampleExpandLevel: "all",
          theme: { colors: { primary: { main: "#9f155a" } } },
        }}
      />
    </div>
  );
}

export default FlightBooking;
