import React,{useState} from "react";
import { Container, Typography, Box, Divider, CircularProgress } from "@mui/material";
import headerback from "../assets/image/headerbackground.png";
import GetAppIcon from '@mui/icons-material/GetApp';
// import Button from '@mui/joy/Button';
import Button from '@mui/material/Button';
const Resources = () => {
  const [state,setState] = React.useState({
    postmanButtonLoading:false,
    airlineLogosButtonLoading:false,
    airlineListButtonLoading:false,
    airportListButtonLoading:false,
    countryListButtonLoading:false,
    apiDocumentButtonLoading:false,
    apiErrorButtonLoading:false,
    integrationProcessButtonLoading:false
  });

  const handleDownload = (fileUrl,button) => {
    switch (button) {
      case "postman":
        setState({...state,postmanButtonLoading:true});
        break;
      case "airlineLogos":
        setState({...state,airlineLogosButtonLoading:true});
        break;
      case "airlineList":
        setState({...state,airlineListButtonLoading:true});
        break;
      case "airportList":
        setState({...state,airportListButtonLoading:true});
        break;
      case "countryList":
        setState({...state,countryListButtonLoading:true});
        break;
      case "apiDocument":
        setState({...state,apiDocumentButtonLoading:true});
        break;
      case "apiError":
        setState({...state,apiErrorButtonLoading:true});
        break;
      case "integrationProcess":
        setState({...state,integrationProcessButtonLoading:true});
        break;
      default:
        break;
    }
    fetch(fileUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "gfahub_devlabs_postman_collection.json");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
      }).finally(() => {
        switch (button) {
          case "postman":
            setState({...state,postmanButtonLoading:false});
            break;
          case "airlineLogos":
            setState({...state,airlineLogosButtonLoading:false});
            break;
          case "airlineList":
            setState({...state,airlineListButtonLoading:false});
            break;
          case "airportList":
            setState({...state,airportListButtonLoading:false});
            break;
          case "countryList":
            setState({...state,countryListButtonLoading:false});
            break;
          case "apiDocument":
            setState({...state,apiDocumentButtonLoading:false});
            break;
          case "apiError":
            setState({...state,apiErrorButtonLoading:false});
            break;
          case "integrationProcess":
            setState({...state,integrationProcessButtonLoading:false});
            break;
          default:
            break;
        }
      });
  };

  return (
    <div>
      <div
        style={{
          backgroundImage: "url(" + headerback + ")",
          backgroundSize: "cover",
          //objectFit:"contain",
          height: "10vh",
        }}
      >
        <Container>
          <Typography
            variant="h4"
            style={{ paddingTop: "1.5%", marginBottom: "10px", color: "white" }}
          >
            Resources
          </Typography>
        </Container>
      </div>
      <Container>
        <Box style={{marginTop:"30px", marginBottom:"20px"}}>
          <Typography variant="h4">Postman Collection</Typography>
          <Button
          onClick={() => handleDownload("https://docs.getfares.com/files/gfahub_devlabs_postman_collection.json","postman")}
            variant="contained"
            sx={{minWidth:'12vw'}}
            color="primary"
            endIcon={state.postmanButtonLoading ? '':<GetAppIcon />}
          >
                  {state.postmanButtonLoading ? <CircularProgress size={24} color="inherit" /> : "Download"}
          </Button>
          <Divider style={{marginTop:"20px"}} />
          <Typography variant="h4" style={{marginTop:"20px"}}>Airline Logos</Typography>
         <Button
            variant="contained"
            sx={{minWidth:'12vw'}}
            color="primary"
            endIcon={state.airlineLogosButtonLoading ? '':<GetAppIcon />}
            onClick={() => handleDownload("https://docs.getfares.com/files/gfahub_devlabs_airlinelogos.zip","airlineLogos")}
          >
            {state.airlineLogosButtonLoading ? <CircularProgress size={24} color="inherit" /> : "Download"}
          </Button>
          <Divider style={{marginTop:"20px"}} />
          <Typography variant="h4" style={{marginTop:"20px"}}>Airline List</Typography>
         <Button
            variant="contained"
            sx={{minWidth:'12vw'}}
            color="primary"
            endIcon={state.airlineListButtonLoading ? '':<GetAppIcon />}
            onClick={() => handleDownload("https://docs.getfares.com/files/gfahub_devlabs_airlinelist.csv","airlineList")}
          >
            {state.airlineListButtonLoading ? <CircularProgress size={24} color="inherit" /> : "Download"}
          </Button>
          <Divider style={{marginTop:"20px"}} />
          <Typography variant="h4" style={{marginTop:"20px"}}>Airport List</Typography>
          <Button
            variant="contained"
            sx={{minWidth:'12vw'}}
            color="primary"
            onClick={() => handleDownload("https://docs.getfares.com/files/gfahub_devlabs_airportslist.csv","airportList")}
            endIcon={state.airportListButtonLoading ?'':<GetAppIcon />}
          >
            {state.airportListButtonLoading ? <CircularProgress size={24} color="inherit" /> : "Download"}
          </Button>
          <Divider style={{marginTop:"20px"}} />
          <Typography variant="h4" style={{marginTop:"20px"}}>Country List</Typography>
          <Button
            variant="contained"
            sx={{minWidth:'12vw'}}
            color="primary"
            onClick={() => handleDownload("https://docs.getfares.com/files/gfahub_devlabs_countrylist.csv","countryList")}
            endIcon={state.countryListButtonLoading ?'':<GetAppIcon />}
          >
            {state.countryListButtonLoading ? <CircularProgress size={24} color="inherit" /> : "Download"}
          </Button>
		  <Divider style={{marginTop:"20px"}} />
          <Typography variant="h4" style={{marginTop:"20px"}}>API Documentation</Typography>
          <Button
            variant="contained"
            sx={{minWidth:'12vw'}}
            color="primary"
            onClick={() => handleDownload("https://docs.getfares.com/files/API_Documentation.pdf","apiDocument")}
            endIcon={state.apiDocumentButtonLoading ? '':<GetAppIcon />}
          >
            {state.apiDocumentButtonLoading ? <CircularProgress size={24} color="inherit" /> : "Download"}
          </Button>
		  <Divider style={{marginTop:"20px"}} />
          <Typography variant="h4" style={{marginTop:"20px"}}>API Errors</Typography>
          <Button
            variant="contained"
            sx={{minWidth:'12vw'}}
            color="primary"
            onClick={() => handleDownload("https://docs.getfares.com/files/API_errors.pdf","apiError")}
            endIcon={state.apiErrorButtonLoading ? '' :<GetAppIcon />}
          >
            {state.apiErrorButtonLoading ? <CircularProgress size={24} color="inherit" /> : "Download"}
          </Button>
		  <Divider style={{marginTop:"20px"}} />
          <Typography variant="h4" style={{marginTop:"20px"}}>Integration Process Overview</Typography>
          <Button
            variant="contained"
            sx={{minWidth:'12vw'}}
            color="primary"
            onClick={() => handleDownload("https://docs.getfares.com/files/IntegrationProcessOverview.pdf","integrationProcess")}
            endIcon={state.integrationProcessButtonLoading ? '' :<GetAppIcon />}
          >
            {state.integrationProcessButtonLoading ? <CircularProgress size={24} color="inherit" /> : "Download"}
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default Resources;
