import React from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Menu from "@mui/material/Menu";
import { MenuItem, Divider, Paper, Typography } from "@mui/material";
import logo from "../assets/image/logo.png";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../Redux/AuthSlice";

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
  logo: {
    height: "4em",
    [theme.breakpoints.down("md")]: {
      height: "4em",
    },
    [theme.breakpoints.down("xs")]: {
      height: "4em",
    },
  },
  appbar: {
    zIndex: theme.zIndex.modal + 1,
    borderTop: "5px solid brown"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  loginButton: {
    marginRight: "10px",
  },
  tabContainer: {
    marginLeft: "auto",
    marginRight: "25px",
  },
  tab: {
    ...theme.typography.tab,
    minWidth: 10,
    marginLeft: "10px",
  },
  scaddDrawer: {
    width: 350,
    flexShrink: 0,
    zIndex: theme.zIndex.drawer + 1,
  },
  scaddDrawerPaper: {
    width: 350,
    paddingTop: 64,
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: "#e4e4e4",
    boxShadow:
      "0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  //cart related Css
  cart: {
    padding: "10px",
  },
  CartType: {
    display: "flex",
    fontSize: "1em",
  },
}));
function Header() {
  const classes = useStyles();
  const isLoggedIn = useSelector((state) => state.IsLoggedIn.value);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [restanchorEl, restsetAnchorEl] = React.useState(null);

  const handleClickRest = (event) => {
    restsetAnchorEl(event.currentTarget);
  };

  const handleCloseRest = () => {
    restsetAnchorEl(null);
  };
  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar position="fixed" color="white" className={classes.appbar}>
          <Toolbar disableGutters sx={{ background: "#fff" }}>
            <img
              alt="company logo"
              style={{ width: "220px", marginLeft: "10px" }}
              src={logo}
            />
            <Typography
              style={{
                fontSize: "2em",
                marginLeft: "25px",
                color: "#9f155a",
              }}
            >
              Dev Labs
            </Typography>
            {isLoggedIn ? (
              <React.Fragment>
                <Tabs
                  //value={value}
                  //onChange={handleChange}

                  className={`${classes.tabContainer} tabsContainer`}
                >
                  <Tab
                    className={classes.tab}
                    component={Link}
                    to="/Introduction"
                    label="Introduction"
                  ></Tab>
                  <Tab
                    className={classes.tab}
                    label="Quick Start"
                    onClick={handleClick}
                  // onMouseOver={handleClick}
                  // onMouseOut={handleClose}
                  ></Tab>
                  <Menu
                    id="quick-start-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <Paper
                      elevation={0}
                      style={{ width: "200px" }}
                    // onMouseOut={handleClose}
                    // onMouseOver={handleClick}
                    >
                      <MenuItem
                        component={Link}
                        to="/Checklist"
                        onClick={handleClose}
                      >
                        Checklist
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        component={Link}
                        to="/Certification"
                        onClick={handleClose}
                      >
                        Certification
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        component={Link}
                        to="/WorkFlow"
                        onClick={handleClose}
                      >
                        WorkFlow
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        component="a"
                        href="https://gfa-hub.statuspage.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Status Page
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        component={Link}
                        to="/Resources"
                        onClick={handleClose}
                      >
                        Resources
                      </MenuItem>
                    </Paper>
                  </Menu>
                  <Tab
                    className={classes.tab}
                    label="REST Api"
                    onClick={handleClickRest}
                  // onMouseOver={handleClickRest}
                  // onMouseOut={handleCloseRest}
                  ></Tab>
                  <Menu
                    id="simple-menu"
                    anchorEl={restanchorEl}
                    keepMounted
                    open={Boolean(restanchorEl)}
                    onClose={handleCloseRest}
                  >
                    <Paper
                      elevation={0}
                      style={{ width: "200px" }}
                    >
                      <React.Fragment>
                        <MenuItem
                          component={Link}
                          to="/FlightSearch"
                          onClick={handleCloseRest}
                        >
                          Flight Search
                        </MenuItem>
                        <Divider />
                        <MenuItem
                          component={Link}
                          to="/FlightRevalidation"
                          onClick={handleCloseRest}
                        >
                          Flight Revalidation
                        </MenuItem>
                        <Divider />
                        <MenuItem
                          component={Link}
                          to="/FlightBooking"
                          onClick={handleCloseRest}
                        >
                          Flight Booking
                        </MenuItem>
                        <Divider />
                        <MenuItem
                          component={Link}
                          to="/PostTicketing"
                          onClick={handleCloseRest}
                        >
                          PostTicketing
                        </MenuItem>
                      </React.Fragment>
                    </Paper>
                  </Menu>
                </Tabs>
                <Button
                  component={Link}
                  to="/"
                  variant="contained"
                  color="primary"
                  size="large"
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => dispatch(logout())}
                  style={{
                    textTransform: "none",
                    width: "200px",
                    marginRight: "25px",
                  }}
                >
                  Logout
                </Button>
              </React.Fragment>
            ) : (
              <div></div>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin} />
    </React.Fragment>
  );
}

export default Header;
