import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ProtectedRoute({ children, redirectPath }) {
  const user = useSelector((state) => state.IsLoggedIn.value);

  return user ? children : <Navigate to={redirectPath} replace />;
}

export default ProtectedRoute;
