import React from 'react';
import ReactDOM from 'react-dom/client';  // Import from 'react-dom/client' instead of 'react-dom'
import './index.css';
import App from './App';
import store from './Redux/Store';
import { Provider } from 'react-redux';

// Create a root element using createRoot
const root = ReactDOM.createRoot(document.getElementById('root'));

// Use the new root.render() method
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
