import { Container,Box, Divider } from '@mui/material'
import React from 'react'

function Footer() {
    return (
        <div>
            <Divider />
            <Container style={{padding:"4.5vh"}}>
                <Box display="flex" sx={{justifyContent:"center"}}>
                    <Box>
                    @Copy Rights 2021 - Akbar Offshore Pvt. Ltd.
                    </Box>
                </Box>
            </Container>
        </div>
    )
}

export default Footer
