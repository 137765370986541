import React from "react";
import { Container, Typography, Box, Divider } from "@mui/material";
import headerback from "../assets/image/headerbackground.png";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "60%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    marginTop: theme.spacing(2),
  },
}));

const Checklist = () => {
  const classes = useStyles();

  return (
    <div>
      <div
        style={{
          backgroundImage: "url(" + headerback + ")",
          backgroundSize: "cover",
          //objectFit:"contain",
          height: "10vh",
        }}
      >
        <Container>
          <Typography
            variant="h4"
            style={{ paddingTop: "1.5%", marginBottom: "10px", color: "white" }}
          >
            Checklist
          </Typography>
        </Container>
      </div>
      <Container>
        <Box style={{ marginTop: "50px", marginBottom: "30px" }}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              color: "#6a6767",
            }}
          >
            Get Credentails
          </Typography>
          <Typography variant="body1">
            As soon as the GFA-HUB team creates the Client ID, it sends an email
            notification along with the set password link to the client.
          </Typography>
          <Divider style={{ marginTop: "20px", marginBottom: "50px" }} />
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              color: "#6a6767",
            }}
          >
            Get Token
          </Typography>
          <Typography variant="body1">
            You can use these credentials to get a GFA-HUB API token in order to
            call APIs. Use PostMan if you are using a sessionless token.
            <div className={classes.root}>
              <Alert severity="info">
                See REST APIs: PostMan requests under the resource. Use SoapUI
                if you are using a session token.
              </Alert>
            </div>
          </Typography>
          <Divider style={{ marginTop: "20px", marginBottom: "50px" }} />
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              color: "#6a6767",
            }}
          >
            Download Sample Json
          </Typography>
          <Typography variant="body1">
            You can download a sample JSON file See APIs Json: under the Reset
            APIs and start your testing with Sandbox.GFA-Hub.com. If you want to
            play with some of our REST APIs, navigate to the REST APIs page,
            then try it out.
          </Typography>
          <div className={classes.root}>
            <Alert severity="info">
              Tip: you can see our API response and request payloads for each
              REST API.
            </Alert>
            <Alert severity="info">
              Note: Token need to manage at your end.
            </Alert>
          </div>
        </Box>
      </Container>
    </div>
  );
};

export default Checklist;
