import React from "react";
import { Container, Typography, Box, Divider, Button } from "@mui/material";
import headerback from "../assets/image/headerbackground.png";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import GetAppIcon from "@mui/icons-material/GetApp";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "60%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    marginTop: theme.spacing(2),
  },
}));

const Certification = () => {
  const classes = useStyles();

  return (
    <div>
      <div
        style={{
          backgroundImage: "url(" + headerback + ")",
          backgroundSize: "cover",
          //objectFit:"contain",
          height: "10vh",
        }}
      >
        <Container>
          <Typography
            variant="h4"
            style={{ paddingTop: "1.5%", marginBottom: "10px", color: "white" }}
          >
            Certification
          </Typography>
        </Container>
      </div>
      <Container>
        <Box style={{ marginTop: "50px", marginBottom: "20px" }}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              color: "#6a6767",
            }}
          >
            Start the Integration
          </Typography>
          <ul style={{ listStylePosition: "inside", marginLeft: "10px" }}>
            <li style={{ marginBottom: "20px" }}>
              Start the integration into your platform as described in the
              documentation including Success and Error scenarios.
            </li>
            <li style={{ marginBottom: "20px" }}>
              Use the link below to download the Test plan and update all the
              required information and Test your application for all the
              required use cases against the Sandbox environment.
            </li>
            <li style={{ marginBottom: "20px" }}>
              Submit samples of message from your application (i.e., RQ and RS
              payloads of APIs) with an Updated spreadsheet to the GFA-HUB
              Integration team for review.
            </li>
          </ul>
          <div className={classes.root}>
            <Alert severity="info">
              Note: In addition, the files must be sent in a zip format using
              this naming convention: ClientName-GFA.zip.Please submit the files
              to: IntegrationCertification@AkbarOffshore.com
            </Alert>
          </div>
          <Divider style={{ marginTop: "20px", marginBottom: "50px" }} />
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              color: "#6a6767",
            }}
          >
            Integration Testing
          </Typography>
          <ul style={{ listStylePosition: "inside", marginLeft: "10px" }}>
            <li style={{ marginBottom: "20px" }}>
              Our integration team will validate the messages and schedule a
              date for integration testing.
            </li>
            <li style={{ marginBottom: "20px" }}>
              You will be required to post messages from your application to the
              GFA-HUB Sandbox during integration testing.
            </li>
            <li style={{ marginBottom: "20px" }}>
              It will be a responsibility of the integration team members to
              verify that the messages comply with the workflow and meet the
              requirements.
            </li>
            <li style={{ marginBottom: "20px" }}>
              The testing takes a Maximum of 3 days to ensure no errors occur on
              any of the APIs.
            </li>
          </ul>
          <div className={classes.root}>
            <Alert severity="info">
              Note: Test plan will be validated by the integration team.
            </Alert>
          </div>
          <Button
            component={Link}
            to="/files/gfahub_UAT_Test_Plan.xlsx"
            target="_blank"
            variant="contained"
            color="primary"
            endIcon={<GetAppIcon />}
            download
            style={{ marginTop: "10px" }}
          >
            Download UAT Test Plan
          </Button>
          <Divider style={{ marginTop: "20px", marginBottom: "50px" }} />
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              color: "#6a6767",
            }}
          >
            Commercial
          </Typography>
          <ul style={{ listStylePosition: "inside", marginLeft: "10px" }}>
            <li style={{ marginBottom: "20px" }}>
              Your Account Manager will provide you with the necessary clearance
              for commercial.
            </li>
          </ul>
          <Divider style={{ marginTop: "20px", marginBottom: "50px" }} />
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              color: "#6a6767",
            }}
          >
            Congratulations!
          </Typography>
          <ul style={{ listStylePosition: "inside", marginLeft: "10px" }}>
            <li style={{ marginBottom: "20px" }}>
              Now that your application has been verified, you are considered
              certified.
            </li>
            <li style={{ marginBottom: "20px" }}>
              Our Integration team will be providing you the Credentials for
              Production with the GFA-HUB certificate.
            </li>
          </ul>
        </Box>
      </Container>
    </div>
  );
};

export default Certification;
