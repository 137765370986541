import { createTheme } from '@mui/material/styles';

const gfsPrimary = "#9f155a";
const gfsSecondary = "#808080";

export default createTheme({
  palette: {
    primary: {
      main: gfsPrimary,
    },
    secondary: {
      main: gfsSecondary,
    },
  },
  typography: {
    color: "black",
    fontFamily: "Poppins, sans-serif",  // Added fallback font
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    tab: {
      textTransform: "none",
      fontWeight: 700,
      fontSize: "1em",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          disableElevation: true, // disables the drop shadow on all Buttons
          textTransform: "none",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        wrapper: {
          flexDirection: "row",  // keeps the tab labels aligned horizontally
        },
      },
    },
  },
});
